import {
    configureStore as SourceConfigureStore,
    getStore as SourceGetStore,
    noopReducer as SourceNoopReducer
} from 'SourceUtil/Store/index';

export const configureStore = SourceConfigureStore;

export const noopReducer = SourceNoopReducer;

export const getStore = SourceGetStore;

/**
 * Obtener Url Media (en produccion vendra cdn)
 * @namespace Elganso/Util/Store/Index/getUrlMedia
 */
export const getUrlMedia = () => getStore().getState().ConfigReducer.secure_base_media_url;

export default getStore;
