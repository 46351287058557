/* eslint-disable no-undef */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { getUrlMedia } from 'Util/Store';

import YBGoogleMaps from './YBGoogleMaps.component';

/** @namespace Elganso/Component/YBGoogleMaps/Container/mapStateToProps */
export const mapStateToProps = (_state) => ({});

/** @namespace Elganso/Component/YBGoogleMaps/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({});

/** @namespace Elganso/Component/YBGoogleMaps/Container */
export class YBGoogleMapsContainer extends PureComponent {
    static propTypes = {
        activePoint: PropTypes.string,
        points: PropTypes.arrayOf(PropTypes.objectOf).isRequired,
        type: PropTypes.string
    };

    static defaultProps = {
        activePoint: null,
        type: 'ybgooglemaps'
    };

    state = {
        activeMarker: {},
        mapCenterLat: 40,
        mapCenterLng: -3,
        mapLoaded: false,
        mapZoom: 5,
        markerIcon: `${getUrlMedia()}scandiweb/images/map_marker.png`,
        selectedPlace: { name: 'aux' },
        showingInfoWindow: false
    };

    containerFunctions = {
        localizar: this.localizar.bind(this),
        onMapClicked: this.onMapClicked.bind(this),
        onMarkerClick: this.onMarkerClick.bind(this),
        onMapIdle: this.onMapIdle.bind(this),
        onMarkerMounted: this.onMarkerMounted.bind(this)
    };

    containerProps() {
        const { activePoint, points, type } = this.props;
        const {
            activeMarker,
            mapCenterLat,
            mapCenterLng,
            mapLoaded,
            mapZoom,
            markerIcon,
            selectedPlace,
            showingInfoWindow
        } = this.state;

        return {
            activeMarker,
            activePoint,
            mapCenterLat,
            mapCenterLng,
            mapLoaded,
            mapZoom,
            markerIcon,
            points,
            selectedPlace,
            showingInfoWindow,
            type
        };
    }

    localizar() {
        const geocoder = new google.maps.Geocoder();
        const { points } = this.props;
        const tienda = points[0];

        const args = {
            address: tienda.codpostal,
            region: tienda.codpais
        };

        // eslint-disable-next-line no-restricted-globals
        if (typeof tienda.codpostal === 'string' && !isNaN(tienda.codpostal) && !isNaN(parseInt(tienda.codpostal, 10))) {
            args.componentRestrictions = {
                postalCode: tienda.codpostal,
                country: tienda.codpais
            };
        }

        const self = this;
        geocoder.geocode(args, (results, status) => {
            if (status !== google.maps.GeocoderStatus.OK) {
                // Si falla tomamos madrid como centro y devolvemos
                self.setState(
                    {
                        nearbyShops: points,
                        mapZoom: 7,
                        mapCenterLat: 40,
                        mapCenterLng: -3
                    }
                );

                return;
            }

            const { location } = results[0].geometry;
            const lat = location.lat().toFixed(4);
            const lng = location.lng().toFixed(4);

            const newTiendasState = {
                nearbyShops: points,
                mapCenterLat: lat,
                mapCenterLng: lng,
                mapZoom: 7
            };

            if (points.length > 0) {
                newTiendasState.mapCenterLat = tienda.latitud;
                newTiendasState.mapCenterLng = tienda.longitud;
            }

            self.setState(newTiendasState);
        });
    }

    onMapClicked() {
        const { showingInfoWindow } = this.state;

        if (showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                activeMarker: null
            });
        }
    }

    onMarkerClick(props, marker, _e) {
        this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true,
            mapZoom: 10
        });
    }

    onMapIdle() {
        this.setState({ mapLoaded: true });
    }

    onMarkerMounted(e) {
        if (e !== null && e !== undefined) {
            this.onMarkerClick(e.props, e.marker, e);
        }
    }

    render() {
        return (
            <YBGoogleMaps
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(YBGoogleMapsContainer)
);
