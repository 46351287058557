import Image from 'Component/Image';
import {
    Loader as SourceLoader
} from 'SourceComponent/Loader/Loader.component';
import { getUrlMedia } from 'Util/Store';

import './Loader.override.style';

/** @namespace Elganso/Component/Loader/Component */
export class LoaderComponent extends SourceLoader {
    renderMain() {
        return (
            <div block="Loader" elem="Main">
                <Image src={ `${getUrlMedia()}scandiweb/images/cargando-app.gif` } />
            </div>
        );
    }
}

export default LoaderComponent;
